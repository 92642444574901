import React from "react";
import { toast } from "react-toastify";

import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { userService } from "services";

const baseUrl = process.env.REACT_APP_BASE_URL;

class Roles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            res: [],
            open: false,
            openEdit: false,
            openDelete: false,
            id: "",
            name: "",
            spinner: false,
            role_name: "",
            permissions: [],
            userPermissions: []
        }
    }
    componentDidMount() {
        this.setState({ spinner: true })
        this.getAllRoles()
        this.handleAddRole()
    }

    getAllRoles = () => {
        let auth = localStorage.getItem('token');
        fetch(baseUrl + '/roles', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + auth,
            },
        }).then(data => data.json())
            .then(res => {
                this.setState({
                    res: res.data,
                    spinner: false
                })
            }).catch();
    }
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    addPermission = (permission) => {
        const userperm = this.state.userPermissions
        const index = this.state.userPermissions.findIndex(userPermission => JSON.stringify(userPermission) === JSON.stringify(permission))
        if (index > -1) {
            userperm.splice(index, 1);
            this.setState({ userPermissions: userperm })
        } else {
            userperm.push(permission);
            this.setState({ userPermissions: userperm })
        }
    }
    handleUpdate = () => {
        const data = {
            role_name: this.state.role,
            permissions: this.state.userPermissions
        }
        userService.addRole(data).then(res => {
            toast.success("Role Added Successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            this.setState({
                res: res.data,
                open: !this.state.open
            })
        })
    }
    handleAddRole = () => {
        let auth = localStorage.getItem('token');
        fetch(baseUrl + "/permissions", {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + auth,
            },
        }).then(data => data.json())
            .then(res => {
                this.setState({
                    permissions: res
                })
            }).catch();
    }
    handleEdit = (data) => {
        this.setState({
            openEdit: true,
            id: data.id,
            role_name: data.role_name,
            userPermissions: data.userPermissions
        })
    }
    isChecked = (permission) => {
        return this.state.userPermissions.findIndex(userperm => JSON.stringify(userperm) === JSON.stringify(permission)) > -1 ? true : false;
    }
    handleDelete = (data) => {
        this.setState({
            openDelete: true,
            id: data.id
        })
    }
    deleteRole = () => {
        this.setState({ openDelete: true })
        let auth = localStorage.getItem('token');
        fetch(baseUrl + "/roles/" + this.state.id, {
            method: 'DELETE',
            headers: {
                'Authorization': "Bearer " + auth,
            },
        }).then(data => data.json())
            .then(res => {
                toast.success("Role Deleted Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                this.setState({
                    res: res.data,
                    openDelete: !this.state.openDelete
                })
            }).catch();
    }
    editRole = () => {
        const data = {
            id: this.state.id,
            role_name: this.state.role_name,
            permissions: this.state.userPermissions
        }
        let auth = localStorage.getItem('token');
        const requestOptions = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + auth,
            },
            body: JSON.stringify(data)
        };
        fetch(baseUrl + '/roles', requestOptions).then(data => data.json())
            .then(res => {
                toast.success("Role Updated Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.setState({
                    openEdit:!this.state.openEdit,
                    res:res.data
                })
            }).catch();
    }
    updatePermission = (permission) => {
        const userperm = this.state.userPermissions
        const index = this.state.userPermissions.findIndex(userPermission => JSON.stringify(userPermission) === JSON.stringify(permission))
        if (index > -1) {
            userperm.splice(index, 1);
            this.setState({ userPermissions: userperm })
        } else {
            userperm.push(permission);
            this.setState({ userPermissions: userperm })
        }
    }
    permissionList = () => {
        return (
            <>
                <Row>
                    <Col md="">
                        <Label style={{ fontWeight: "bold" }}>Permissions</Label>
                    </Col>
                </Row>
                <Row style={{ marginLeft: 0 }}>
                    {this.state.permissions.map((permission) => {
                        return (
                            <Col key={permission.id} md="3">
                                <Input onChange={() => this.updatePermission(permission)} type="checkbox"
                                    name="role"
                                    value={this.state.permissions.id}
                                    checked={this.isChecked(permission)}></Input>
                                <Label>{permission.name}</Label>
                            </Col>
                        )
                    })}
                </Row>
            </>
        )
    }
    render() {
        return (
            <>
                <div className="header bg-gradient-info pb-8 pt-md-6">
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col>
                                    <Button
                                        color="success"
                                        size="md"
                                        onClick={() => this.setState({ open: true })}
                                    >
                                        Add Role
                                    </Button>
                                </Col>
                                {/* Add Role Modal */}
                                <Modal size="lg" centered={true} isOpen={this.state.open}>
                                    <ModalBody>
                                        <div style={{ marginBottom: 20 }}>
                                            <h2>Add Role</h2>
                                        </div>
                                        <Form>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Role</Label>
                                                        <Input
                                                            name="role"
                                                            value={this.state.role}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter Role">
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="">
                                                    <Label style={{ fontWeight: "bold" }}>Permissions</Label>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginLeft: 0 }}>
                                                {this.state.permissions.map(permission => {
                                                    return (
                                                        <Col key={permission.id} md="3">
                                                            <Input onChange={() => this.addPermission(permission)} type="checkbox"></Input>
                                                            <Label>{permission.name}</Label>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="success"
                                            size="md"
                                            onClick={this.handleUpdate}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            color="danger"
                                            size="md"
                                            onClick={() => this.setState({ open: !(this.state.open) })}>
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </Modal>

                                {/* Edit Role Modal */}

                                <Modal size="lg" centered={true} isOpen={this.state.openEdit}>

                                    <ModalBody>
                                        <div style={{ marginBottom: 20 }}>
                                            <h2>Edit Role</h2>
                                        </div>
                                        <Form>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>ID</Label>
                                                        <Input
                                                            readOnly
                                                            name="id"

                                                            value={this.state.id}

                                                        >
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Role</Label>
                                                        <Input
                                                            name="role_name"
                                                            value={this.state.role_name}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter Role">
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {this.permissionList()}
                                        </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="success"
                                            size="md"
                                            onClick={this.editRole}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            color="danger"
                                            size="md"
                                            onClick={() => this.setState({ openEdit: !(this.state.openEdit) })}>
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>

                                {/* Delete Modal */}

                                <Modal size="md" centered={true} isOpen={this.state.openDelete}>
                                    <ModalBody>
                                        <h2>Delete Role</h2>
                                        <p>Are you sure you want to Delete this Role?</p>
                                        <div className="float-right">
                                            <Button
                                                color="danger"
                                                size="sm"
                                                onClick={() => this.deleteRole()}>
                                                Ok
                                            </Button>
                                            <Button
                                                color="success"
                                                size="sm"
                                                onClick={() => this.setState({ openDelete: !(this.state.openDelete) })}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </ModalBody>
                                </Modal>

                            </Row>
                        </div>
                    </Container>
                </div>
                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Authorities</h3>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Edit/Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.res && this.state.res.map(record =>
                                            <tr key={record.id}>
                                                <td>{record.id}</td>
                                                <td>{record.role_name}</td>
                                                <td>
                                                    <Button onClick={() => this.handleEdit({ id: record.id, role_name: record.role_name, userPermissions: record.permissions })} size="sm" color="default">Edit</Button>
                                                    {/* <Button onClick={() => this.handleDelete({ id: record.id})} size="sm" color="danger">Delete</Button> */}
                                                </td>
                                            </tr>
                                        )}
                                        {this.state.spinner &&
                                            <tr>
                                                <td colSpan={3} style={{ textAlign: "center" }}>
                                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                                </td>
                                            </tr>
                                        }
                                        {this.state.res && !this.state.res.length && !this.state.spinner &&
                                            <tr>
                                                <td colSpan={3} style={{ textAlign: "center" }}>
                                                    <div className="p-2">No Permission To Display</div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    };
}
export default Roles;
