import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { userService } from "../services"

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

const User = (props) => {

    const userRoutes = [];

    const mainContent = React.useRef(null);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/user" && userService.permission(prop.permission)) {
                return (
                    <Route
                        path={"/csiv2" + prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    const createRoutes = (routes) => {
        routes.forEach(route => {
            if (userService.permission(route.permission)) {
                route.role.forEach(role => {
                    if (role === "user") {
                        userRoutes.push(route)
                    }
                })
            }
        })
    }

    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (
                props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
                -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };

    return (
        <>
            {createRoutes(routes)}
            <Sidebar
                {...props}
                routes={userRoutes}
                logo={{
                    innerLink: "/user/home",
                    imgSrc: require("../assets/img/brand/zong.png").default,
                    imgAlt: "...",
                }}
            />
            <div className="main-content" ref={mainContent}>
                <AdminNavbar
                    {...props}
                    brandText={getBrandText(props.location.pathname)}
                />
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="*" to="/csiv2/user/home" />
                </Switch>
            </div>
        </>
    );
};

export default User;
