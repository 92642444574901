import React from "react";
import { userService } from "../../services";
import jwtDecode from "jwt-decode";
import { toast } from 'react-toastify';
import moment from 'moment';
import Pagination from "react-js-pagination";


// import Pagination from "components/Pagination/Pagination";

import {
  Card,
  Col,
  CardBody,
  Button,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter
} from "reactstrap";

const baseUrl = process.env.REACT_APP_BASE_URL;
toast.configure()

class SmsDetailsSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: "",
      selectval: "sent",
      msisdn: "",
      startdate: "",
      enddate: "",
      bmsisdn: "",
      activePage: 0,
      recordPerPage: 10,
      totalElements: "",
      res: null,
      pagination: false,
      spinner: false
    }

    var today = new Date();

    var priorDate = new Date().setDate(today.getDate()-30)

    var futureDate = new Date().setDate(today.getDate()+30)

    console.log("today: "+ moment(today).format("YYYY-MM-DD") + "priordate:" + moment(priorDate).format("YYYY-MM-DD")  + "futureDate:" + moment(futureDate).format("YYYY-MM-DD"))
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = event => {
    console.log("EVENT" + this.state.value)
    event.preventDefault();
   
    this.setState({ activePage: 0,
    value: this.state.value })
    const data = {
      activePage: 0,
      recordPerPage: this.state.recordPerPage,
      msisdn: this.state.msisdn,
      value: this.state.value,
      select: this.state.select === "" ? null : this.state.select,
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      // bmsisdn: this.state.bmsisdn === "" ? null : this.state.bmsisdn
    }
    const decoded = jwtDecode(localStorage.getItem('token'));
    if (decoded.exp < new Date().getTime())
      this.SmsDetails(data);
  }
  SmsDetails(data) {
    this.setState({
      spinner: true,
      res: null
    })

    let auth = localStorage.getItem('token');
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + auth,
    },
  };
  return fetch(baseUrl + '/smsrecord?page=' +  this.state.activePage + "&pageSize=" + this.state.recordPerPage + "&msisdn=" + this.state.msisdn + "&selectval=" + this.state.selectval + "&startDate=" + this.state.startdate + " 00:00:00&endDate=" + this.state.enddate + " 23:59:59", requestOptions).then(data => 
    data.json()).then(res => {
      // console.log("HERE IS THE RESPONSE: " + res.data.content)
      this.setState({
        res: res.data.content,
        spinner: false,
        pagination: true,
        totalElements: res.data.totalElements
      })
    }).catch();
    // fetch(baseUrl + '/sentsms?page=' + this.state.activePage + "&pageSize=" + this.state.recordPerPage + "&msisdn=" + this.state.msisdn + "&selectval=" + this.state.value + "&startDate=" + params.startdate + " 00:00:00&endDate=" + params.enddate + " 00:00:00"',requestOptions {
    // }).then(data =>
    //     data.json())
    //     .then(res => {
    //         this.setState({
    //             roles: res.data
    //         })
    //     }).catch();

    // userService.createSMS(data).then(response => {
    //   if (response.data.empty) {
    //     toast.warning("No result found against this MSISDN", {
    //       position: "top-right",
    //       autoClose: 3000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     this.setState({
    //       res: null,
    //       pagination: false,
    //       spinner: false
    //     })
    //   } else {
    //     //  console.log(response);
    //     this.setState({ spinner: false })
    //     this.setState({ pagination: true })
    //     this.setState({ res: response.data.content })
    //     // this.setState({ currentPage: response.data.pageable.pageNumber })
    //     this.setState({ totalElements: response.data.totalElements })
    //   }
    // }).catch();
  }
  // paginate = pageNumber => {
  //   const data = {
  //     msisdn: this.state.msisdn,
  //     select: this.state.select === "" ? null : this.state.select,
  //     startdate: this.state.startdate,
  //     enddate: this.state.enddate,
  //     bmsisdn: this.state.bmsisdn === "" ? null : this.state.bmsisdn
  //   }
  //   this.smsDetails(data, pageNumber - 1)
  // };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber - 1 });
    const data = {
      activePage: pageNumber - 1,
      recordPerPage: this.state.recordPerPage,
      msisdn: this.state.msisdn,
      select: this.state.select === "" ? null : this.state.select,
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      bmsisdn: this.state.bmsisdn === "" ? null : this.state.bmsisdn
    }
    this.smsDetails(data)
  }
  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Label style={{ fontWeight: 'bold' }}>MSISDN <small style={{ color: "red", fontSize: 15 }}>*</small></Label>
                              <Input
                                value={this.state.msisdn}
                                onChange={this.handleChange}
                                name="msisdn"
                                type="tel"
                                style={{ borderColor: this.state.error ? "lightpink" : "lightgray", backgroundColor: this.state.error ? "lightpink" : "white" }}
                                placeholder="Enter MSISDN"
                                maxLength={10}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>

                              <Label style={{ fontWeight: 'bold' }}>SENT/RECEIVED</Label>
                              {/* <Input
                                value={this.state.bmsisdn}
                                onChange={this.handleChange}
                                name="bmsisdn"
                                type="tel"
                                placeholder="Enter B-party MSISDN"
                                maxLength={10}
                              ></Input> */}
                              <select name="selectval" value={this.state.value} className="form-control" onChange={this.handleChange}>
                                <option value="sent">Sent</option>
                                <option value="received">Received</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label style={{ fontWeight: 'bold' }}>Start Date <small style={{ color: "red", fontSize: 15 }}>*</small></Label>
                              <Input
                                value={this.state.startdate}
                                onChange={this.handleChange}
                                style={{ borderColor: this.state.error ? "lightpink" : "lightgray", backgroundColor: this.state.error ? "lightpink" : "white" }}
                                name="startdate"
                                type="date"
                                min={moment(new Date().setDate( new Date().getDate()-30)).format("YYYY-MM-DD")}
                                max={moment().format("YYYY-MM-DD")}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label style={{ fontWeight: 'bold' }}>End Date <small style={{ color: "red", fontSize: 15 }}>*</small></Label>
                              <Input
                                value={this.state.enddate}
                                onChange={this.handleChange}
                                style={{ borderColor: this.state.error ? "lightpink" : "lightgray", backgroundColor: this.state.error ? "lightpink" : "white" }}
                                name="enddate"
                                type="date"
                                min={this.state.startdate}
                                max={moment().format("YYYY-MM-DD")}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <Button
                              className="float-right"
                              color="success"
                              onClick={this.handleSubmit}
                              size="md"
                              type="submit"
                              disabled={
                                this.state.msisdn !== "" &&
                                  this.state.startdate !== "" &&
                                  this.state.enddate !== "" ?
                                  this.state.spinner ? true : false : true
                              }
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">SMS Details Summary</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Sent TIme</th>
                      <th scope="col">Sender</th>
                      <th scope="col">Receiver</th>
                      <th scope="col">Sender VN</th>
                      <th scope="col">Receiver VN</th>
                      <th scope="col">Message</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.res && this.state.res.map(record =>
                      <tr key={record.smsLogId.id}>
                        <td>{record.smsLogId.sentTime}</td>
                        <td>{record.smsLogId.sndr}</td>
                        <td>{record.smsLogId.rcvr}</td>
                        <td>{record.smsLogId.sndrVn}</td>
                        <td>{record.smsLogId.rcvrVn}</td>
                        <td>{record.smsLogId.message}</td>
                        <td>{record.smsLogId.action}</td>
                      </tr>
                    )}
                    {this.state.spinner ?
                      <tr>
                        <td colSpan={9} style={{ textAlign: "center" }}>
                          <div className="spinner-border spinner-border-lg align-center"></div>
                        </td>
                      </tr>
                      :
                      this.state.res == null || Object.keys(this.state.res).length === 0 ? <tr>
                        <td colSpan={9} style={{ textAlign: "center" }}>
                          <div className="p-2">No Result To Display</div>
                        </td>
                      </tr> : null
                    }

                    {/* {this.state.res == null || Object.keys(this.state.res).length === 0 ?  <tr>
                        <td colSpan={9} style={{ textAlign: "center" }}>
                          <div className="p-2">No Result To Display</div>
                        </td>
                      </tr> : null} */}
                  </tbody>
                </Table>
                {this.state.pagination ?
                  <CardFooter className="py-2">
                    {/* <Pagination
                      currentPage={this.state.currentPage}
                      recordPerPage={this.state.recordPerPage}
                      totalRecord={this.state.totalElements}
                      paginate={this.paginate}
                    /> */}
                    <Pagination
                      activePage={this.state.activePage + 1}
                      itemsCountPerPage={Number(this.state.recordPerPage)}
                      totalItemsCount={Number(this.state.totalElements)}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </CardFooter>
                  :
                  null
                }
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

export default SmsDetailsSummary;
