import React from "react";
import { alertService, userService } from "../../services";
import jwtDecode from "jwt-decode";
import { toast } from 'react-toastify';

import {
  Card,
  CardHeader,
  Col,
  Button,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Container,
  InputGroup,
  Row,
  Modal,
  ModalBody
} from "reactstrap";

const baseUrl = process.env.REACT_APP_BASE_URL;
toast.configure()

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msisdn: "",
       res: null,
       res2: "",
       open: false,
       filtermodal: false,
      //  openbulkunsub: true,
      //  selectedFile: null,
      spinner: false
    }
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleSubmit = event => {
    event.preventDefault();
    const data = {
      msisdn: this.state.msisdn
    }
    this.searchUser(data);
  }
  handleUnsub = event => {
    event.preventDefault();
    const data = {
      msisdn: this.state.msisdn,
    }
    this.onUnSub(data);
  }

  // handleBulkUnsub = event => {
  //   event.preventDefault();
  //   const data = new FormData() 
  //   data.append( 
  //     "myFile", 
  //     this.state.selectedFile, 
  //     this.state.selectedFile.name 
  //   ); 
  //   console.log(data)
  //   this.onBulkUnSub(data);
  // }

   unSub = () => {
      if (userService.permission("UNSUB"))
         return (
            <Button
               onClick={() => this.setState({ open: !this.state.open })}
               className="float-right"
               color="danger"
               size="md"
               disabled={this.state.res ? this.state.res.status === "UnSubscribed" ? true : false : true}
            >
               Unsub
            </Button>
         )
   }

//    bulkUnSub = () => {
//     // if (userService.permission("UNSUB"))
//        return (
//           <Button
//              onClick={() => this.setState({ openbulkunsub: !this.state.openbulkunsub })}
//              className="float-right"
//              color="danger"
//              size="md"
//              disabled={this.state.res ? this.state.res.status === "UnSubscribed" ? true : false : true}
//           >
//              Bulk Unsub
//           </Button>
//        )
//  }
//  onChangeHandler=event=>{

//   this.setState({
//     selectedFile: event.target.files[0],
//     loaded: 0,
//   })

// }
  onUnSub(data) {
    const token = localStorage.getItem('token')
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token,
      },
    };
    fetch(baseUrl + '/unsub/' + data.msisdn, requestOptions).then(data =>
      toast.success("Unsubscription Successful", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }),
      this.setState({ open: !this.state.open }),
      this.searchUser(data)
    )
  }

  showFilters = (data) => {
    this.setState({
      virtualMSISDN: data.virtualMSISDN,
      filtermodal: !this.state.filtermodal,
      // res: null
    })
    console.log(data);
    let auth = localStorage.getItem('token');
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + auth,
      },
    };
    fetch(baseUrl + '/filters?msisdn=' + data.virtualMSISDN, requestOptions).then(data =>
      data.json()).then(res => {
        // console.log("HERE IS THE RESPONSE: " + JSON.stringify(res.data.telcoMSISDN))
        this.setState({
          res2: res.data,
          spinner: !this.state.spinner
        })
      }).catch();
  }

  // onBulkUnSub(data) {
  //   const token = localStorage.getItem('token')
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       'Authorization': "Bearer " + token,
  //     },
  //    body: data
  //   };
  //   console.log(data);
  //   fetch(baseUrl + '/upload', requestOptions).then(data =>
  //     toast.success("Unsubscription Successful", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     }),
  //     this.setState({ open: !this.state.open }),
  //     this.handleSubmit()
  //   )
  // }
  searchUser(data) {
    this.setState({ spinner: true })
    const decoded = jwtDecode(localStorage.getItem("token"));
    if (decoded.exp < new Date().getTime())
      return userService
        .numberMapping(data.msisdn)
        .then((res) => {
          if (res.data !== null)
            this.setState({
              res: res.data,
              spinner: false
            })
          else {
            toast.warning("No result found against this MSISDN", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
                  spinner: false ,
                  res: null,
               })

          }
        })
        .catch(alertService.error);
  }
  render() {
    return (
      <>
      {/* Filter Modal */}
      <Modal size="md" centered={true} isOpen={this.state.filtermodal}>

<ModalBody>

  <h2>Filters</h2>
  {/* {console.log(" RES2: " + this.state.res2)} */}
  {this.state.res2 !== null ?
    <div style={{ textAlign: "center" }}>
      <Label style={{ fontWeight: "bold" }}>STATUS:
        {this.state.res2.status === 1 ?
          <span style={{ marginLeft: 5, color: "green", fontWeight: "normal" }}>
            ACTIVE
          </span> :
          <span style={{ marginLeft: 5, color: "red", fontWeight: "normal" }}>
            INACTIVE
          </span>}
      </Label>
      <br></br>

      <Label style={{ fontWeight: "bold" }}>Call Status: <span style={{ fontWeight: "normal" }}>{this.state.res2.callStatus} </span></Label> <br></br>
      <Label style={{ fontWeight: "bold" }}>SMS Status: <span style={{ fontWeight: "normal" }}>{this.state.res2.smsstatus} </span></Label> <br></br>
      <Label style={{ fontWeight: "bold" }}>Time: <span style={{ fontWeight: "normal" }}>{this.state.res2.tstamp} </span></Label> <br></br>

    </div>

    : <div>
      <p> NO FILTERS EXIST AGAINST THIS MSISDN </p>
    </div>}

  <div className="float-right">
    <Button
      color="success"
      size="sm"
      onClick={() =>
        this.setState({ filtermodal: !this.state.filtermodal})}>

      Close
    </Button>

  </div>

</ModalBody>

</Modal>
        <Modal size="md" centered={true} isOpen={this.state.open}>
          <ModalBody>
            <h2>Unsubscribe confirmation</h2>
            <p>Are you sure you want to Unsubscribe</p>
            <div className="float-right">
              <Button
                color="danger"
                size="sm"
                onClick={this.handleUnsub}>
                Ok
              </Button>
              <Button
                color="success"
                size="sm"
                onClick={() => this.setState({ open: !(this.state.open) })}>
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Modal>
        {/* <Modal size="md" centered={true} isOpen={this.state.openbulkunsub}>
          <ModalBody>
            <h2>Bundle Unsubscribe confirmation</h2>
            <p> </p>
            <input type="file" name="file" onChange={this.onChangeHandler}/>
            <div className="float-right">
              <Button
                color="danger"
                size="sm"
                onClick={this.handleBulkUnsub}>
                Upload
              </Button>
              <Button
                color="success"
                size="sm"
                onClick={() => this.setState({ openbulkunsub: !(this.state.openbulkunsub) })}>
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Modal> */}
        <div className="header bg-gradient-info pb-8 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col sm="7">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="7">
                            <FormGroup className="mb-3">
                              <Label style={{ fontWeight: 'bold' }}>MSISDN (31xxxxxxxx)</Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  name="msisdn"
                                  placeholder="Enter MSISDN (31xxxxxxxx)"
                                  value={this.state.name}
                                  onChange={this.handleChange}
                                  type="text"
                                  maxLength={10}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col style={{ paddingTop: "5.5%", marginLeft: "6%" }}>
                            <Row>
                              <Button
                                onClick={this.handleSubmit}
                                className="float-right"
                                color="success"
                                size="md"
                                type="submit"
                                disabled={this.state.msisdn === "" ? true : this.state.spinner ? true : false}
                              >
                                Submit
                              </Button>
                              {/*<Button*/}
                              {/*  onClick={() => this.setState({ open: !this.state.open })}*/}
                              {/*  className="float-right"*/}
                              {/*  color="danger"*/}
                              {/*  size="md"*/}
                              {/*  disabled={this.state.res ? this.state.res.status === "UnSubscribed" ? true : false : true}*/}
                              {/*>*/}
                              {/*  Unsub*/}
                              {/*</Button>*/}
                               {this.unSub()}
                              
                            </Row>
                            {/* {this.bulkUnSub()} */}
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">User Record</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Virtual MSISDN</th>
                      <th scope="col">Telco MSISDN</th>
                      <th scope="col">Status</th>
                      <th scope="col">Billed Upto</th>
                      <th scope="col">Telco Type</th>
                      <th scope="col">Subscription Date</th>
                      <th scope="col">Unsub Date</th>
                      <th scope="col">Unsub Mode</th>
                      <th scope="col">Filters</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.res !== null ?
                      <tr key={this.state.res.id}>
                      {this.state.res.virtualMSISDN !== null ?
                        <td>{this.state.res.virtualMSISDN}</td> : <td>NA</td>}
                      {this.state.res.telcoMSISDN !== null ?
                        <td>{this.state.res.telcoMSISDN}</td> : <td>NA</td>}
                      {this.state.res.status !== null ?
                        <td>{this.state.res.status}</td> : <td>NA</td>}
                      {this.state.res.billedUpto !== null ?
                        <td>{this.state.res.billedUpto}</td> : <td>NA</td>}
                      {this.state.res.telcoType !== null ?
                        <td>{this.state.res.telcoType}</td> : <td>NA</td>}
                      {this.state.res.subscriptionDate !== null ?
                        <td>{this.state.res.subscriptionDate}</td> : <td>NA</td>}
                      {this.state.res.unSubDate !== null ?
                        <td>{this.state.res.unSubDate}</td> : <td>NA</td>}
                      {this.state.res.unSubMode !== null ?
                        <td>{this.state.res.unSubMode}</td> : <td>NA</td>}
                         <td>
                          <Button
                            onClick={() =>
                              this.showFilters({ virtualMSISDN: this.state.res.virtualMSISDN })}
                            size="sm"
                            color="default">

                            Show Filters
                          </Button>
                        </td>
                    </tr>
                      :
                      this.state.spinner ?
                        <tr>
                          <td colSpan={9} style={{ textAlign: "center" }}>
                            <div className="spinner-border spinner-border-lg align-center"></div>
                          </td>
                        </tr> :
                        < tr >
                          <td colSpan={9} style={{ textAlign: "center" }}>
                            <div className="p-2">No Result To Display</div>
                          </td>
                        </tr>
                    }
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

export default Home;
