import React from "react";
import jwtDecode from "jwt-decode";
import { alertService, userService } from "../../services";
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import SearchField from "react-search-field";

// import Pagination from "components/Pagination/Pagination";

import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";

const baseUrl = process.env.REACT_APP_BASE_URL;
toast.configure()

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            res: [],
            roles: [],
            authTypes: [],
            firstname: "",
            id: "",
            lastname: "",
            username: "",
            email: "",
            password: "",
            cpassword: "",
            mobile: "",
            role: "",
            authenticationType: "",
            open: false,
            openEdit: false,
            openDelete: false,
            recordPerPage: "10",
            response: [],
            totalElements: "",
            pagination: false,
            permissions: null,
            activePage: 1,
        }
    }
    componentDidMount = () => {
        this.fetchResponse((this.state.activePage) - 1)
        this.getAllRoles()
        this.getAuthTypes()
    }
    getAllRoles = () => {
        let auth = localStorage.getItem('token');
        fetch(baseUrl + '/roles', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + auth,
            },
        }).then(data => data.json())
            .then(res => {
                this.setState({
                    roles: res.data
                })
            }).catch();
    }
    getAuthTypes = () => {
        let auth = localStorage.getItem('token');
        fetch(baseUrl + '/authenticationtypes', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + auth,
            },
        }).then(data => data.json())
            .then(res => {
                this.setState({
                    authTypes: res
                })
            }).catch();
    }
    fetchResponse = (pageNumber) => {
        userService.users(baseUrl + '/users/' + pageNumber + '/' + this.state.recordPerPage).then(response => {
            if (response.data.empty) {
                toast.warning("No Result Found", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                this.setState({ pagination: true })
                this.setState({ res: response.data.content })
                this.setState({ totalElements: response.data.totalElements })
            }
        }).catch();
    }

    searchUser = (event) => {
        if (event === "") {
            console.log("EMPTY USER")
            let auth = localStorage.getItem('token');
            fetch(baseUrl + '/users/0/10', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + auth,
                },
            }).then(data =>
                data.json())
                .then(res => {
                    if (res.respCode !== 404 || res !== null || res !== "") {
                        console.log(res);
                        this.setState({
                            res: res.data.content
                        })
                    }
                }).catch();
        }
        else {
            let auth = localStorage.getItem('token');
            fetch(baseUrl + '/smartuser/' + event, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + auth,
                },
            }).then(data =>
                data.json())
                .then(res => {
                    if (res.respCode !== 404 || res !== null || res !== "") {
                        console.log(res);
                        this.setState({
                            res: res.data
                        })
                    }
                }).catch();

        }

        // console.log(event)
        // userService.searchUser(event);

        // userService.users(baseUrl + '/users/' + this.state.recordPerPage).then(response => {
        //     if (response.data.empty) {
        //         toast.warning("No Result Found", {
        //             position: "top-right",
        //             autoClose: 3000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //         });
        //     } else {
        //         this.setState({ pagination: true })
        //         this.setState({ res: response.data.content })
        //         this.setState({ totalElements: response.data.totalElements })
        //     }
        // }).catch();
        // this.setState({
        //     [event.target.name]: event.target.value
        // });
    }
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleRole = event => {
        const index =
            this.state.roles.findIndex(role => JSON.stringify(role.role_name) === JSON.stringify(event.target.value)) > -1 ?
                this.state.roles.findIndex(role => JSON.stringify(role.role_name) === JSON.stringify(event.target.value)) : -1;
        if (index !== -1) {
            this.setState({
                [event.target.name]: event.target.value,
                permissions: this.state.roles[index].permissions,
                role: this.state.roles[index]

            });
        } else {
            this.setState({
                [event.target.name]: event.target.value,
                permissions: null,
                role: null
            });
        }
    }

    handleAuthType = event => {
        const index =
            this.state.authTypes.findIndex(auth => JSON.stringify(auth.name) === JSON.stringify(event.target.value)) > -1 ?
                this.state.authTypes.findIndex(auth => JSON.stringify(auth.name) === JSON.stringify(event.target.value)) : -1;
        if (index !== -1) {
            this.setState({
                [event.target.name]: event.target.value,
                authenticationType: this.state.authTypes[index]
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value,
                authenticationType: null
            });
        }
    }

    resetModal = () => {
        this.setState({
            firstname: '',
            lastname: '',
            username: '',
            email: '',
            mobile: '',
            role: '',
            permissions: '',
            authenticationType: '',
            password: '',
            confirmPassword: ''
        })

    }

    handleEdit = (data) => {
        this.setState({
            openEdit: !this.state.openEdit,
            id: data.id,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            role: data.role,
            mobile: data.mobile,
            authenticationType: data.authenticationType
        })
    }
    handleDelete = () => {
        let auth = localStorage.getItem('token');
        fetch(baseUrl + '/users/' + this.state.id, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + auth,
            },
        }).then(data => data.json())
            .then(res => {
                if (res.respCode === 200) {
                    toast.success("User Has Been Deleted", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        openDelete: !this.state.openDelete,
                        res: res.data.content,
                        pagination: true,
                        totalElements: res.data.totalElements,
                    })

                }
                else if (res.respCode === 401) {
                    toast.warning(res.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        openDelete: !this.state.openDelete
                    })

                }

            }).catch();
    }
    handleSubmit = event => {
        event.preventDefault();
        const data = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            username: this.state.username,
            email: this.state.email,
            mobile: this.state.mobile,
            role: this.state.role,
            permission: this.state.permissions,
            authenticationType: this.state.authenticationType,
            password: this.state.password,
            confirmPassword: this.state.cpassword
        }
        this.onFormSubmit(data);
    }
    onFormSubmit(data) {
        const decoded = jwtDecode(localStorage.getItem('token'));
        if (decoded.exp < new Date().getTime()) {
            return userService.create(data)
                .then((res) => {
                    if (res.respCode === 200) {
                        toast.success("User Has Been Created", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            res: res.data.content,
                            pagination: true,
                            totalElements: res.data.totalElements,
                            open: !this.state.open
                        })
                        this.resetModal();

                    }
                    else if (res.respCode === 401) {
                        toast.warning(res.message, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.setState({
                            open: !this.state.open
                        })
                        this.resetModal();

                    }

                })
                .catch(alertService.error);
        }
    }

    editUser = event => {
        event.preventDefault();
        const data = {
            id: this.state.id,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            mobile: this.state.mobile,
            role: this.state.role,
            permission: this.state.permissions,
            authenticationType: this.state.authenticationType,
        }
        this.onEditFormSubmit(data);
    }

    onEditFormSubmit(data) {
        const decoded = jwtDecode(localStorage.getItem('token'));
        if (decoded.exp < new Date().getTime()) {
            return userService.edit(data)
                .then((res) => {
                    toast.success("User Has Been Updated Successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.setState({
                        res: res.data,
                        pagination: true,
                        openEdit: !this.state.openEdit
                    })
                    this.resetModal();
                })
                .catch(alertService.error);
        }
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.fetchResponse(pageNumber - 1)
    }
    render() {
        return (
            <>
                {/* Edit Modal */}
                <Modal size="lg" centered={true} isOpen={this.state.openEdit}>
                    <ModalBody>
                        <div style={{ marginBottom: 20 }}>
                            <h2>Edit User</h2>
                        </div>
                        <Form>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Id</Label>
                                        <Input
                                            readOnly
                                            name="id"
                                            defaultValue={this.state.id}
                                        ></Input>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>First Name</Label>
                                        <Input
                                            name="firstname"
                                            defaultValue={this.state.firstname}
                                            onChange={this.handleChange}
                                            placeholder="Enter First Name"
                                        ></Input>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Last Name</Label>
                                        <Input
                                            name="lastname"
                                            defaultValue={this.state.lastname}
                                            onChange={this.handleChange}
                                            placeholder="Enter Last Name"
                                        ></Input>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Email</Label>
                                        <Input
                                            name="email"
                                            defaultValue={this.state.email}
                                            onChange={this.handleChange}
                                            placeholder="Enter Email"
                                        ></Input>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Role</Label>
                                        <Input
                                            type="select"
                                            defaultValue={this.state.role.role_name}
                                            onChange={this.handleRole}
                                            name="role"
                                        >
                                            <option>Select Role</option>
                                            {this.state.roles.map(role => {
                                                return (
                                                    <option key={role.id}>{role.role_name}</option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Authorization Type</Label>
                                        <Input
                                            type="select"
                                            defaultValue={this.state.authenticationType.name}
                                            onChange={this.handleAuthType}
                                            name="authenticationType"
                                        >
                                            <option>Select Auth Type</option>
                                            {this.state.authTypes.map(type => {
                                                return (
                                                    <option key={type.id}>{type.name}</option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label style={{ fontWeight: 'bold' }}>Mobile</Label>
                                        <Input
                                            defaultValue={this.state.mobile}
                                            onChange={this.handleChange}
                                            placeholder="Enter Mobile"
                                            name="mobile"
                                        // maxLength={10}
                                        ></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* <Row>
                                {this.state.permissions &&
                                    <Col md="">
                                        <Label style={{ fontWeight: "bold" }}>Permissions</Label>
                                    </Col>
                                }
                            </Row>
                            <Row style={{ marginLeft: 0 }}>
                                {this.state.permissions && this.state.permissions.map(permission => {
                                    return (
                                        <Col key={permission.id} md="3">
                                            <Input type="checkbox" checked readOnly></Input>
                                            <Label>{permission.name}</Label>
                                        </Col>
                                    )
                                })}
                            </Row> */}
                        </Form>
                        <div className="float-right">
                            <Button
                                color="danger"
                                size="md"
                                onClick={this.editUser}>
                                Save
                            </Button>
                            <Button
                                color="success"
                                size="md"
                                onClick={() => this.setState({
                                    openEdit: !this.state.openEdit,
                                    firstname: '',
                                    lastname: '',
                                    username: '',
                                    email: '',
                                    mobile: '',
                                    role: '',
                                    permissions: '',
                                    authenticationType: '',
                                    password: '',
                                    confirmPassword: ''
                                })}>
                                Cancel
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                {/* Delete Modal */}
                <Modal size="md" centered={true} isOpen={this.state.openDelete}>
                    <ModalBody>
                        <h2>Delete confirmation</h2>
                        <p>Are you sure you want to Delete</p>
                        <div className="float-right">
                            <Button
                                color="danger"
                                size="sm"
                                onClick={this.handleDelete}
                            >
                                Ok
                            </Button>
                            <Button
                                color="success"
                                size="sm"
                                onClick={() => this.setState({ openDelete: !(this.state.openDelete) })}>
                                Cancel
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                <div className="header bg-gradient-info pb-8 pt-md-6">
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col>
                                    <Button
                                        color="success"
                                        size="md"
                                        onClick={() => this.setState({ open: !(this.state.open) })}
                                    >
                                        Add User
                                    </Button>
                                </Col>
                                {/* Add User Modal */}
                                <Modal size="lg" centered={true} isOpen={this.state.open}>
                                    <ModalBody>
                                        <div style={{ marginBottom: 20 }}>
                                            <h2>Add User</h2>
                                        </div>
                                        <Form>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>First Name</Label>
                                                        <Input
                                                            name="firstname"
                                                            value={this.state.firstname}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter First Name">
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Last Name</Label>
                                                        <Input
                                                            name="lastname"
                                                            value={this.state.lastname}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter Last Name"
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Username</Label>
                                                        <Input
                                                            value={this.state.username}
                                                            onChange={this.handleChange}
                                                            name="username"
                                                            placeholder="Enter Username"
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Email</Label>
                                                        <Input
                                                            value={this.state.email}
                                                            onChange={this.handleChange}
                                                            name="email"
                                                            placeholder="Enter Email"
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="password"
                                                            placeholder="Enter Password"
                                                            value={this.state.password}
                                                            onChange={this.handleChange}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Confirm Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="cpassword"
                                                            placeholder="Confirm Password"
                                                            value={this.state.cpassword}
                                                            onChange={this.handleChange}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Mobile Number</Label>
                                                        <Input
                                                            value={this.state.mobile}
                                                            onChange={this.handleChange}
                                                            name="mobile"
                                                            placeholder="Enter Mobile Number"
                                                        // maxLength={10}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Role</Label>
                                                        <Input
                                                            type="select"
                                                            value={this.state.role.role_name}
                                                            onChange={this.handleRole}
                                                            name="role"
                                                        >
                                                            <option>Select Role</option>
                                                            {this.state.roles.map(role => {
                                                                return (
                                                                    <option key={role.id}>{role.role_name}</option>
                                                                )
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label style={{ fontWeight: 'bold' }}>Select Auth Type</Label>
                                                        <Input
                                                            type="select"
                                                            value={this.state.authenticationType.name}
                                                            onChange={this.handleAuthType}
                                                            name="authenticationType"
                                                        >
                                                            <option>Select Type</option>
                                                            {this.state.authTypes.map(type => {
                                                                return (
                                                                    <option key={type.id}>{type.name}</option>
                                                                )
                                                            })}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {this.state.permissions &&
                                                    <Col md="">
                                                        <Label style={{ fontWeight: "bold" }}>Permissions</Label>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row style={{ marginLeft: 0 }}>
                                                {this.state.permissions && this.state.permissions.map(permission => {
                                                    return (
                                                        <Col key={permission.id} md="3">
                                                            <Input type="checkbox" checked readOnly></Input>
                                                            <Label>{permission.name}</Label>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            color="success"
                                            size="md"
                                            onClick={this.handleSubmit}>
                                            Submit
                                        </Button>
                                        <Button
                                            color="danger"
                                            size="md"
                                            onClick={() => this.setState({
                                                open: !(this.state.open),
                                                firstname: '',
                                                lastname: '',
                                                username: '',
                                                email: '',
                                                mobile: '',
                                                role: '',
                                                permissions: '',
                                                authenticationType: '',
                                                password: '',
                                                confirmPassword: ''
                                            })}>
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </Row>
                        </div>
                    </Container>
                </div>
                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Authorities</h3>
                                    <div style={{ textAlign: "right" }}>
                                        <SearchField
                                            placeholder="Search..."
                                            onChange={this.searchUser}
                                            searchText=""
                                            classNames="test-class"
                                        />
                                    </div>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Auth Type</th>
                                            <th scope="col">Edit/Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.res && this.state.res.map(record =>
                                            <tr key={record.id}>
                                                <td>{record.id}</td>
                                                <td>{record.firstname}</td>
                                                <td>{record.lastname}</td>
                                                <td>{record.email}</td>
                                                <td>{record.mobile}</td>
                                                <td>{record.user_role.role_name}</td>
                                                <td>{record.authenticationType.name}</td>
                                                <td>
                                                    <Button
                                                        onClick={() => this.handleEdit({ id: record.id, firstname: record.firstname, lastname: record.lastname, email: record.email, role: record.user_role, mobile: record.mobile, authenticationType: record.authenticationType })}
                                                        size="sm"
                                                        color="default">
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.setState({
                                                            openDelete: !this.state.openDelete,
                                                            id: record.id
                                                        })}
                                                        size="sm"
                                                        color="danger">
                                                        Delete
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                        {!this.state.res &&
                                            <tr>
                                                <td colSpan={8} style={{ textAlign: "center" }}>
                                                    <div className="spinner-border spinner-border-lg align-center"></div>
                                                </td>
                                            </tr>
                                        }
                                        {this.state.res && !this.state.res.length &&
                                            <tr>
                                                <td colSpan={8} style={{ textAlign: "center" }}>
                                                    <div className="p-2">No Users To Display</div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                                {this.state.pagination ?
                                    <CardFooter className="py-2">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={Number(this.state.recordPerPage)}
                                            totalItemsCount={Number(this.state.totalElements)}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </CardFooter>
                                    :
                                    null
                                }
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    };
}
export default Users;
