import Home from "views/examples/Home";
import SmsDetails from "views/examples/SmsDetails"
import CallDetails from "views/examples/CallDetails";
import Users from "views/examples/Users";
import Report from "views/examples/Report";
import Roles from "views/examples/Roles";
import Login from "views/examples/Login";
import CallDetailsSummary from "views/examples/CallDetailsSummary";
import SmsDetailsSummary from "views/examples/SmsDetailsSummary";

var routes = [
  {
    path: "/home",
    name: "Home",
    permission: "HOME",
    icon: "ni ni-shop text-info",
    component: Home,
    layout: "/user",
    role: ["user"]
  },
  {
    path: "/sms-details",
    name: "SMS Details",
    permission: "SMS_DETAILS",
    icon: "ni ni-send text-success",
    component: SmsDetails,
    layout: "/user",
    role: ["user"]
  },
  {
    path: "/sms-details-summary",
    name: "SMS Details Summary",
    permission: "SMS_DETAILS",
    icon: "ni ni-book-bookmark text-danger",
    component: SmsDetailsSummary,
    layout: "/user",
    role: ["user"]
  },
  {
    path: "/call-details",
    name: "Call Details",
    permission: "CALL_DETAILS",
    icon: "ni ni-mobile-button text-danger",
    component: CallDetails,
    layout: "/user",
    role: ["user"]
  },
  {
    path: "/call-details-summary",
    name: "Call Details Summary",
    permission: "CALL_DETAILS",
    icon: "ni ni-mobile-button text-danger",
    component: CallDetailsSummary,
    layout: "/user",
    role: ["user"]
  },
  {
    path: "/user-permissions",
    name: "Users",
    permission: "USERS",
    icon: "ni ni-vector text-silver",
    component: Users,
    layout: "/user",
    role: ["user"]
  },
  {
    path: "/roles",
    name: "Roles",
    permission: "ROLES",
    icon: "ni ni-book-bookmark text-danger",
    component: Roles,
    layout: "/user",
    role: ["user"]
  },
  {
    path: "/report",
    name: "Report",
    permission: "REPORT",
    icon: "ni ni-paper-diploma text-yellow",
    component: Report,
    layout: "/user",
    role: ["user"]
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-paper-diploma text-danger",
    component: Login,
    layout: "/auth",
    role: []
  }
];
export default routes;
