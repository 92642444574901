import React from "react";
import { userService } from "../../services";
import jwtDecode from "jwt-decode";
import { toast } from 'react-toastify';
import moment from 'moment';
import Pagination from "react-js-pagination";

// import Pagination from "components/Pagination/Pagination";

import {
  Card,
  Col,
  CardBody,
  Button,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter
} from "reactstrap";

toast.configure()

class SmsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: "",
      msisdn: "",
      startdate: "",
      enddate: "",
      bmsisdn: "",
      activePage: 0,
      recordPerPage: 10,
      totalElements: "",
      res: null,
      pagination: false,
      spinner: false
    }
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleSubmit = event => {
    event.preventDefault();
    this.setState({ activePage: 0 })
    const data = {
      activePage: 0,
      recordPerPage: this.state.recordPerPage,
      msisdn: this.state.msisdn,
      select: this.state.select === "" ? null : this.state.select,
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      bmsisdn: this.state.bmsisdn === "" ? null : this.state.bmsisdn
    }
    const decoded = jwtDecode(localStorage.getItem('token'));
    if (decoded.exp < new Date().getTime())
      this.smsDetails(data);
  }
  smsDetails(data) {
    this.setState({
      spinner: true,
      res: null
    })
    userService.createSMS(data).then(response => {
    if (response.data.empty) {
        toast.warning("No result found against this MSISDN", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          res: null,
          pagination: false,
          spinner: false
        })
      } else {
      //  console.log(response);
        this.setState({ spinner: false })
        this.setState({ pagination: true })
        this.setState({ res: response.data.content })
        // this.setState({ currentPage: response.data.pageable.pageNumber })
        this.setState({ totalElements: response.data.totalElements })
      }
    }).catch();
  }
  // paginate = pageNumber => {
  //   const data = {
  //     msisdn: this.state.msisdn,
  //     select: this.state.select === "" ? null : this.state.select,
  //     startdate: this.state.startdate,
  //     enddate: this.state.enddate,
  //     bmsisdn: this.state.bmsisdn === "" ? null : this.state.bmsisdn
  //   }
  //   this.smsDetails(data, pageNumber - 1)
  // };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber - 1 });
    const data = {
      activePage: pageNumber - 1,
      recordPerPage: this.state.recordPerPage,
      msisdn: this.state.msisdn,
      select: this.state.select === "" ? null : this.state.select,
      startdate: this.state.startdate,
      enddate: this.state.enddate,
      bmsisdn: this.state.bmsisdn === "" ? null : this.state.bmsisdn
    }
    this.smsDetails(data)
}
  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Label style={{ fontWeight: 'bold' }}>MSISDN <small style={{ color: "red", fontSize: 15 }}>*</small></Label>
                              <Input
                                value={this.state.msisdn}
                                onChange={this.handleChange}
                                name="msisdn"
                                type="tel"
                                style={{ borderColor: this.state.error ? "lightpink" : "lightgray", backgroundColor: this.state.error ? "lightpink" : "white" }}
                                placeholder="Enter MSISDN"
                                maxLength={10}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label style={{ fontWeight: 'bold' }}>B-party MSISDN</Label>
                              <Input
                                value={this.state.bmsisdn}
                                onChange={this.handleChange}
                                name="bmsisdn"
                                type="tel"
                                placeholder="Enter B-party MSISDN"
                                maxLength={10}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label style={{ fontWeight: 'bold' }}>Start Date <small style={{ color: "red", fontSize: 15 }}>*</small></Label>
                              <Input
                                value={this.state.startdate}
                                onChange={this.handleChange}
                                style={{ borderColor: this.state.error ? "lightpink" : "lightgray", backgroundColor: this.state.error ? "lightpink" : "white" }}
                                name="startdate"
                                type="date"
                                max={moment().format("YYYY-MM-DD")}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label style={{ fontWeight: 'bold' }}>End Date <small style={{ color: "red", fontSize: 15 }}>*</small></Label>
                              <Input
                                value={this.state.enddate}
                                onChange={this.handleChange}
                                style={{ borderColor: this.state.error ? "lightpink" : "lightgray", backgroundColor: this.state.error ? "lightpink" : "white" }}
                                name="enddate"
                                type="date"
                                max={moment().format("YYYY-MM-DD")}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <Button
                              className="float-right"
                              color="success"
                              onClick={this.handleSubmit}
                              size="md"
                              type="submit"
                              disabled={
                                this.state.msisdn !== "" &&
                                  this.state.startdate !== "" &&
                                  this.state.enddate !== "" ?
                                  this.state.spinner ? true : false : true
                              }
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">SMS Details</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">A-party</th>
                      <th scope="col">B-party</th>
                      <th scope="col">A-party VN</th>
                      <th scope="col">B-party VN</th>
                      <th scope="col">Message</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.res && this.state.res.map(record =>
                      <tr key={record.smsLogId.id}>
                        <td>{record.smsLogId.sentTime}</td>
                        <td>{record.smsLogId.sender}</td>
                        <td>{record.smsLogId.rcvr}</td>
                        <td>{record.smsLogId.sndr_vn}</td>
                        <td>{record.smsLogId.rcvr_vn}</td>
                        <td>{record.smsLogId.message}</td>
                        <td>{record.smsLogId.action}</td>
                      </tr>
                    )}
                    {this.state.spinner ?
                      <tr>
                        <td colSpan={9} style={{ textAlign: "center" }}>
                          <div className="spinner-border spinner-border-lg align-center"></div>
                        </td>
                      </tr>
                      :
                      this.state.res == null || Object.keys(this.state.res).length === 0 ?  <tr>
                        <td colSpan={9} style={{ textAlign: "center" }}>
                          <div className="p-2">No Result To Display</div>
                        </td>
                      </tr> : null
                    }
                    {/* {!this.state.spinner &&
                      <tr>
                        <td colSpan={9} style={{ textAlign: "center" }}>
                          <div className="p-2">No Result To Display</div>
                        </td>
                      </tr>
                    } */}
                    {/* {this.state.res == null ? <h2>null</h2> : <h2>not null</h2>} */}
                    {}
              </tbody>
                </Table>
                {this.state.pagination ?
                  <CardFooter className="py-2">
                    {/* <Pagination
                      currentPage={this.state.currentPage}
                      recordPerPage={this.state.recordPerPage}
                      totalRecord={this.state.totalElements}
                      paginate={this.paginate}
                    /> */}
                     <Pagination
                      activePage={this.state.activePage + 1}
                      itemsCountPerPage={Number(this.state.recordPerPage)}
                      totalItemsCount={Number(this.state.totalElements)}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </CardFooter>
                  :
                  null
                }
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };
}

export default SmsDetails;
