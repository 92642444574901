import { authHeader } from './auth-header';


const baseUrl = process.env.REACT_APP_BASE_URL;
export const fetchWrapper = {
    get,
    getToken,
    create,
    createPerm,
    createCall,
    createCallv2,
    createSMS,
    numberMapping,
    createAuth,
    createAutth,
    createReport,
    login,
    loginotpp,
    unsub,
    edit,
    editPerm,
    editAuth,
    editAutth,
    getById,
    getByIdSelect,
    getAuthPermissions,
    delete: _delete,
    jwtReq,
    users,
    perms,
    permsById,
    authById,
    autthById,
    generateOTP,
    logout,
    addRole
};


function users(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(url, requestOptions).then(data => data.json())
}
function perms() {


    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(baseUrl + '/userperm', requestOptions).then(data => data.json())
}
function jwtReq(url) {

    let auth = localStorage.getItem('token');
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
    })
        .then(res => res.json())
        .then(data => data.id_token);
}

function getToken(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'username': 'admin',
            'password': 'admin'
        })
    };
    return fetch(baseUrl + '/authenticate', requestOptions).then(data => data.json())
}
function get(url) {

    let auth = localStorage.getItem('token');
    return fetch(baseUrl + '/users', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
    }).then(res => res.json())

}

function getById(url) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
    };
    return fetch(url, requestOptions).then(res => res.json())
}
function getByIdSelect(url) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
    };
    return fetch(url, requestOptions).then(res => res.json())
}

function getAuthPermissions(url) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
    };
    return fetch(url, requestOptions).then(res => res.json())
}
function permsById(url) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
    };
    return fetch(url, requestOptions).then(res => res.json())
}
function authById(url) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
    };
    return fetch(url, requestOptions).then(res => res.json())
}
function autthById(url) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
    };
    return fetch(url, requestOptions).then(res => res.json())
}
function createPerm(url, body) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(body)
    };
    return fetch(baseUrl + '/pmnew', requestOptions).then(data => data.json())
}

function createCall(params) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
    };
    return fetch(baseUrl + '/cdrdetails?page=' + params.activePage + "&pageSize=" + params.recordPerPage + "&msisdn=" + params.msisdn + "&bmsisdn=" + params.bmsisdn + "&startDate=" + params.startdate + " 00:00:00&endDate=" + params.enddate + " 23:59:59", requestOptions).then(data => data.json())
}

function createCallv2(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(url, requestOptions).then(data => data.json())
}

function createSMS(params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(baseUrl + '/smsdetails?page=' + params.activePage + "&pageSize=" + params.recordPerPage + "&msisdn=" + params.msisdn + "&bmsisdn=" + params.bmsisdn + "&startDate=" + params.startdate + " 00:00:00&endDate=" + params.enddate + " 23:59:59", requestOptions).then(data => data.json())
}

function numberMapping(msisdn) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        }
    };
    return fetch(baseUrl + '/numbermappings?msisdn=' + msisdn, requestOptions).then(data => data.json())
}
function createAuth(url, body) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(body)
    };
    return fetch(baseUrl + '/authnew', requestOptions).then(data => data.json())
}

function createAutth(url, body) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(body)
    };
    return fetch(baseUrl + '/autthnew', requestOptions).then(data => data.json())
}


function createReport(url, body) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(body)
    };
    return fetch(baseUrl + '/report', requestOptions).then(data => data.json())
}

function create(url, body) {
    
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(body)
    };
    return fetch(baseUrl + '/users', requestOptions).then(data => data.json())
}
function login(url, body) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(baseUrl + '/authenticate', requestOptions, { mode: 'no-cors' }).then(data => data.json())
}

function loginotpp(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(baseUrl + '/authenticateotp', requestOptions).then(data => data.json())
}


function unsub(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    const urll = baseUrl + '/unsub/' + body;
    return fetch(urll, requestOptions).then(data => data.json())
}
function generateOTP(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };
    return fetch(baseUrl + '/generateOTP', requestOptions).then(data => data.json())
}
function edit(url, body) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(body)
    };
    return fetch(url + "/users", requestOptions).then(data => data.json())
}
function editPerm(url, body) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(data => data.json())
}
function editAuth(url, body) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(data => data.json())
}
function editAutth(url, body) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(data => data.json())
}
function _delete(url) {
    const requestOptions = {
        method: 'DELETE'
    };
    return fetch(url, requestOptions).then(data => data.json())
}

function logout(formData) {
    let auth = localStorage.getItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: formData
    };
    return fetch(baseUrl + "/logout", requestOptions).then(data => data.json())
}

function addRole(roleData) {
    let auth = localStorage.getItem('token');
    console.log("Before Stringify" + roleData)
    const requestOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + auth,
        },
        body: JSON.stringify(roleData)
    };
    console.log("After Stringify" + JSON.stringify(roleData))
    return fetch(baseUrl + "/roles/add", requestOptions).then(data => data.json())
}

// helper functions

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
