import React from "react";
import jwtDecode from "jwt-decode";
import { alertService, userService } from "../../services";

import {
    Card,
    CardHeader,
    Col,
    Button,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
} from "reactstrap";

class Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: "",
            endDate: "",
            msisdn: ""

        }
    }
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleSubmit = event => {
        event.preventDefault();
        const data = {
            fromDate: this.state.fromDate,
            endDate: this.state.endDate,
            msisdn: this.state.msisdn
        }
        this.onFormSubmit(data);
    }
    onFormSubmit(data) {
        const decoded = jwtDecode(localStorage.getItem('token'));
        if (decoded.exp < new Date().getTime())
            return userService.createReport(data)
                .then(() => {
                    alertService.success('User added', { keepAfterRouteChange: true });
                })
                .catch(alertService.error);
    }
    render() {
        return (
            <>
                <div className="header bg-gradient-info pb-8 pt-md-5" />
                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0">Add User</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md="5">
                                                <FormGroup>
                                                    <Label style={{ fontWeight: 'bold' }}>From Date</Label>
                                                    <Input
                                                        name="fromDate"
                                                        placeholder="Enter Date"
                                                        type="date"
                                                    ></Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="5">
                                                <FormGroup>
                                                    <Label style={{ fontWeight: 'bold' }}>End Date</Label>
                                                    <Input
                                                        name="endDate"
                                                        placeholder="Enter Date"
                                                        type="date"
                                                    ></Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="5">
                                                <FormGroup>
                                                    <Label style={{ fontWeight: 'bold' }}>MSISDN (34x-xxxxxxx)</Label>
                                                    <Input
                                                        name="msisdn"
                                                        placeholder="Enter MSISDN"
                                                        maxLength={10}
                                                        type="tel"
                                                    ></Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button
                                            className="float-right"
                                            color="success"
                                            onClick={this.handleSubmit}
                                            size="md"
                                        >
                                            Submit
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    };
}
export default Report;
